@media (min-width:1281px) {
    .allianceClass {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .footerContainer {
        display: flex;
        flex-direction: row;
    }

    .displayNoneAbmDesktop {
        display: none;
    }
    .footer {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        background: #D7D4CF;
        align-items: center !important;
        width: 99vw;
    }

    .textTitleFooter {
        font-weight: 700 !important;
        color: '#C9773D' !important;
        font-size: 60px;
    }
    .textSubTitleFooter{
        margin-top: -60px;
        padding-left: 15% !important;
        font-weight: 700!important;
        font-size: 30px !important;
    }
    .modalLoginFooter {
        display: 'flex' !important;
        flex-direction: 'column' !important;
        justify-content: 'space-around' !important;
        flex-wrap: 'wrap' !important;
    }

    .imgContainerFooter {
        display: flex;
        justify-content: space-around;
    }

    .specialCssImg {
        display: flex !important;
        justify-content: center !important;
    }

    .imgFooter1 {
        width: 243px;
        height: 119px;
    }

    .imgFooter2 {
        width: 162px;
        height: 113px;
    }

    .imgFooter3 {
        width: 148px;
        height: 126px;
    }

    .imgFooter4 {
        width: 348px;
        height: 101px;
    }

    .imgFooter5 {
        width: 125px;
        height: 125px;
    }

    .loginFooter {
        display: 'flex';
        justify-content: center;
        position: 'absolute';
        top: '50%';
        left: '50%';
        transform: 'translate(-50%; -50%)';
        width: '400';
        background-color: 'withe';
    }

}

.specialCssFooter {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

@media (max-width: 1280px) {
    .displayNoneAbmMobile {
        display: none !important;
    }

    .imgContainerFooter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer {
        display: flex;
        justify-content: center;
        flex-direction: column !important;
        align-items: flex-start !important;
        padding: 48px 12px !important;
        background: #D7D4CF !important;
    }

    .specialClassFooter {
        flex-direction: row !important;
        align-items: center !important;
    }

    .imgFooter1 {
        width: 100%;
    }

    .imgFooter2 {
        width: 100%;
    }

    .imgFooter3 {
        width: 100%;
    }

    .imgFooter4 {
        width: 100%;
        ;
    }

    .imgFooter5 {
        width: 90%;
    }


    .textTitleFooter {
        margin-top: -15px;
        font-weight: 700;
        font-size: 28px;
        margin-bottom: -25px;
    }
    .textSubTitleFooter{
        padding-left: 15% !important;
        font-weight: 700!important;
        font-size: 13px !important;
    }
    .allianceClass {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

}
@media (min-width:1281px) {

    .mapSectionContainer {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center;
        width: 100%;

    }

    .leaflet-container {
        width: 50vw;
        height: 100%;
    }

    .citySectionContainer {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        height: 1200px;
        width: 300px;
        background: #F8F9FA;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.49);
        border-radius: 2px;

    }

    .titleCityMap {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .containerMapa {
        height: 100%;
    }

    .nameCityMap {
        font-weight: 700;
        font-size: 20px;
        line-height: 12px;
    }

    .liCityMap {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #BC6C25;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

    .cityMaps {
        height: 30px;
        width: 90%;
    }
}

@media (max-width: 1280px) {
    .mapSectionContainer {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;

    }

    .cityMaps {
        height: 30px;
        width: 90% !important;
    }

    .leaflet-container {
        width: 90vw;
        height: 60vh;
    }

    .citySectionContainer {
        align-items: center;
        flex-direction: column !important;
        padding-left: 5%;
        height: 1274px;
        width: 500px;
        background: #F8F9FA;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.49);
        border-radius: 2px;

    }

    .titleCityMap {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .nameCityMap {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }

    .liCityMap {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #BC6C25;
        width: 100%;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

    .mapaImg {
        padding-top: 5%;
    }

    .containerMapa {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 35px;
    }
}


.App {
    display: flex;
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.sidebar {
    box-sizing: border-box;
    padding: 2em;
    width: 30vw;
}

.sidebar h2,
.sidebar p,
.sidebar ul {
    text-align: left;
}

.sidebar h2 {
    margin-top: 0;
}

.sidebar button {
    width: 100%;
    font-weight: bold;
    padding: .8em 1em;
}
.container{
    width: 100%;
    height: 100%;
}

.backgroundDrawer{
    background-color: white;
}
.DrawerMenu{
    width: 100%;
    height: 50%;
    margin-left: 20%;
    justify-content: center;
}
.textDrawer{
    color:rgb(206, 140, 54)
}
.containerElements{
    width: 100%;  
    height:  150px;
    text-align: center;
}
.textSelected{
    background-color: rgb(206, 140, 54);
    color: white;
    width: 130px
}
.textNotSelected{
    color: rgb(206, 140, 54);;
    width: 130px
}

@media (min-width:1281px) {
    .fullTabla {
        display: flex;
        width: 100%;
        margin-left: 10%;
        margin-top: 1%;
        justify-content: center;
        align-items: center;
    }

    .pageSocios{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 25px;
            justify-content: flex-end;
            position: sticky;
    }
}
@media (max-width:1280px) {
    .fullTabla {
        width: 100%;
        height: 100%;

    }

    .pageSocios{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center !important;
            height: 75px !important;
            justify-content: center;
    }
}
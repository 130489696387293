@media (min-width:1281px) {
    .basicsSection {
        display: flex;
        justify-content: center;
    }
    .specialDivBasics{
        flex-direction: row-reverse !important;
    }
    .basicsMineria {
        display: flex;
        flex-direction: row-reverse;
    }

    .basicsMineriaSecond {
        display: flex;
    }

    .basicsExtraccion {
        display: flex;

    }

    .basicsThird {
        display: flex;
    }

    .basicsTitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }

    .basicsSubtitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .basicsSubtitleColor {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #BC6C25;
    }


    .basicsText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }

    .imgBasics1 {
        width: 600px;
        height: 413px;
    }

    .imgBasics2 {
        width: 602px;
        height: 482px;
    }

    .imgBasics3 {
        width: 600px;
        height: 277px;
    }

    .imgBasics4 {
        width: 600px;
        height: 277px;
    }

    .imgBasics5 {
        width: 602px;
        height: 338px;
    }
    .img456 {
        display: flex;
        justify-content: space-between;
    }


}

@media (max-width: 1280px) {
    .specialAtributsBasics{
        flex-direction: column !important;
        align-items: center !important;
    }
    .basicsSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .basicsTitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }

    .basicsSubtitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .basicsSubtitleColor {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #BC6C25;
    }

    .basicsText {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }
    .specialDiv{
        flex-direction: column !important; 
        align-items: center !important;
    }
      
    .theBasicTextMobile {
        font-size: 12px;
    }
    .imgBasics1 {
        width: 100%;
    }

    .imgBasics2 {
        width: 100%;
    }

    .imgBasics3 {
        width: 100%;
    }

    .imgBasics4 {
        width: 100%;
    }

    .imgBasics5 {
        width: 100%;
    }

}
@media (min-width:1281px) {
    .applicationsContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: center;
    }

    .applicationsTitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }
    .applicationsSection {
        display: flex;
        justify-content: center;
    }

    .applicationsText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .applicationsSubTitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .applicationsSpecialText {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .applicationsAridos {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .applicationsAridosAplicaiones {
        display: flex;
        flex-direction: row-reverse !important;
        justify-content: space-between;
    }

    .applicationsFooter {
        display: flex;
        justify-content: center;
    }
    .imgApplications1 {
        width: 600px;
        height: 258px;
    }

}

@media (max-width: 1280px) {
    .applicationsTitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }

    .applicationsText {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .applicationsSpecialText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .imgApplications1 {
        width: 100%;
    }

.imgApplications2 {
    width: 100%;
}

}
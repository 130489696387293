@media (min-width:1281px) {

    .containerTable {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }
    .mapSection{
        padding-top: 15px;
    }
  
    .headerSectionDesktop{
        margin-bottom: 50px;
    }
    .containerNavigationButton {
        display: 'flex' !important;
        flex-direction: 'row' !important;
        justify-content: 'center' !important;
        width: 100%;
    }

    .btnSelected {
        background-color: rgb(206, 140, 54);
        border-color: white;
        margin: 0;
        padding: 0;
        color: white;
    }

    .btnNotSelected {
        color: white;
        border-color: white;
        margin: 0;
        padding: 0;

    }

    .textSelected {
        background-color: rgb(206, 140, 54);
        color: white;
        width: 130px
    }

    .textNotSelected {
        color: rgb(206, 140, 54);
        ;
        width: 130px
    }

    .containerHeaderNavigation {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btnModalDeleteSocio {
        display: flex;
        flex-direction: row;
        height: 30px;
        width: 150px;
        justify-content: space-between;
    }

    .aLinkExel {
        display: flex;
        text-decoration: none;
    }

    .inputAbmTable {
        width: 'auto';
    }

    .displayNoneAbmDesktop {
        display: none;
    }

    .theadTable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .thTable {

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #BC6C25;
        padding-bottom: 0%;
        width: 200px;
    }

    .thDominioComercial {
        display: flex !important;
        justify-content: flex-start;
        width: 200px;
    }

    .tdContainerRenderTable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 10%;
        align-content: stretch;
        align-items: flex-start;
        padding-bottom: 0%;
    }

    .razonTable {
        width: 10%;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .genericTextTable {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center;
        width: 15%;
        font-size: 16px;
        font-weight: 400;
    }

    .modalAmbSocios {
        display: 'flex';
        flex-direction: row;
        justify-content: space-around;
        position: 'absolute';
        top: '50%';
        left: '50%';
        transform: 'translate(-50%, -50%)';
        
        width: '400';
        background-color: 'background.paper';
        border: '2px solid #000';
    }

    .modalDeleteSocio {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;

    }

    .containerButtonModalDeleteSocio {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .buttonVerTodos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 25px;

    }

    .btnSelecVerTodos {
        background-color: rgb(206, 140, 54) !important;
        border-color: white !important;
        margin: 0 !important;
        color: white !important;
        width: 150px;
        text-decoration: none !important;
    }


}


.tHeadTable {
    display: flex;
    justify-content: space-around;
}




@media (max-width: 1280px) {
    .buttonNavigationMobile {
        width: auto;
    }

    .inputAbmTable {
        width: '90%';
    }

    .thTable {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #BC6C25;
    }
    .mapSection{
        display: flex;
        flex-direction: column !important;
        padding-top: 15px;
    }
    .tdContainerRenderTable {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 10%;
        align-content: stretch;
        align-items: center;
        padding-bottom: 0%;
    }

    .displayNoneAbmMobile {
        display: none;
    }

    .startSection {
        width: 90%;
    }

    .theadTable {
        display: none;
    }

    .thHeadMobile {
        width: 198px;
        height: 29px;
    }

    .pH2Mobile {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
    .containerTable {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }
    .razonTable {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
    .aLinkExel {
        display: flex;
        text-decoration: none;
    }

    .genericTextTable {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        width: 200px;
    }

    .btnSelecVerTodos {
        background-color: rgb(206, 140, 54) !important;
        border-color: white !important;
        margin: 0 !important;
        color: white !important;
        width: 150px;
        text-decoration: none !important;
    }

    .buttonVerTodos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 25px;

    }

    .containerRenderTable {
        width: 100%;
    }

    .btnContainerMobile{
        display: contents;
        color: #BC6C25;
    }
    .btnSelected {
        width: 'auto' !important;
        background-color: rgb(206, 140, 54)!important;
        border-color: white!important;
        width: 25%!important;
        color: white!important;
    }

    .btnNotSelected {
        width: 'auto'!important;
        color: white!important;
        border-color: white!important;
        width: 25%!important;
        margin: 0!important;
        padding: 0!important;

    }

    .textSelected {
        background-color: rgb(206, 140, 54);
        color: white;
        width: 130px
    }

    .textNotSelected {
        color: rgb(206, 140, 54);;
        width: 130px
    }
    .modalAmbSocios {
        display: 'flex';
        flex-direction: row;
        justify-content: center;
        position: 'absolute';
        transform: 'translate(-50%, -50%)';
        width: '400';
        background-color: 'background.paper';
        border: '2px solid #000';
    }
    .mobileNuestroSocios{
        display: flex;
        align-items: center;
    }
    .nuestrosSocios{
        height: 50px;
        margin-top: -15px;
    }
}
@media (min-width:1281px) {
    .theProcessContainer {
        display: flex;
        flex-direction: row;
    }
    .specialDiv{
        display: flex;
        flex-direction: column !important;
    }
    .specialClass{
        justify-content: center !important;
    }
    .theProcessSection {
        display: flex;
        justify-content: center;
    }
    .theProcessTitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
    }
    .theProcessText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
    }
    .imgProcess1 {
        width: 600px;
        height: 251px;
    }

    .imgProcess2 {
        width: 600px;
        height: 279px;
    }

    .imgProcess3 {
        width: 600px;
        height: 298px;
    }

    .imgProcess4 {
        width: 394px;
        height: 301px;
    }

    .imgProcess5 {
        width: 394px;
        height: 301px;
    }

    .imgProcess6 {
        width: 396px;
        height: 301px;
    }

    .imgProcess7 {
        width: 600px;
        height: 265px;
    }

    .imgApplications2 {
        width: 600px;
        height: 346px;
    }


}

/* termina media query desktop */

/* comienzo stilos mobile */
@media (max-width: 1280px) {
    .theProcessContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .specialDiv{
        display: flex;
        flex-direction: column !important;
    }
    .specialClass{
        justify-content: center !important;
    }
    .theProcessTitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
    }
    .theProcessText {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
    }
    .divImgMobile {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .imgProcess1 {
        width: 100%;
    }

    .imgProcess2 {
        width: 100%;
    }

    .imgProcess3 {
        width: 100%;
        justify-content: center;
    }

    .imgProcess4 {
        width: 100%;
    }

    .imgProcess5 {
        width: 100%;
    }

    .imgProcess6 {
        width: 100%;
    }

    .imgProcess7 {
        width: 90%;
    }
}
@media (min-width:1281px) {

    .aboutSectionNavigator {
        display: flex !important;
        justify-content: space-evenly !important;
      
    } 
    .renderSection{
        display: flex;
        justify-content: center !important;
        margin-top: 50px;
    }
    .aboutSectionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .btnSelected {
        background-color: rgb(206, 140, 54);
        border-color: white;
        margin: 0;
        padding: 0;
        color: white;
    }
    
    .btnNotSelected {
        color: white;
        border-color: white;
        margin: 0;
        padding: 0;
    
    }
    

    .textSelected {
        background-color: rgb(206, 140, 54);
        color: white;
        width: 130px;
        font-size: small;
    }

    .textNotSelected {
        color: rgb(206, 140, 54);
        width: 130px;
        font-size: small;

    }
}

@media (max-width: 1280px) {
    .aboutSectionNavigator {
        display: flex;
        justify-content: space-between;
    }

    .btnNotSelected {
        color: white;
        border-color: white;
        padding: 0px !important;
        height: 50%;

    }

    .btnSelected {
        background-color: rgb(206, 140, 54);
        color: white;
        height: 50%;

    }

    .aboutSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:'20px';
    }

    .textSelected {
        background-color: rgb(206, 140, 54);
        color: white;
        width: 100%;
        font-size: small;
    }

    .textNotSelected {
        color: rgb(206, 140, 54);
        width: 100%;
        font-size: small;

    }
}
@media (min-width:1281px) {
    .StartSectionContainer {
        display: flex;
        flex-direction: row !important;
        justify-content: flex-end;

    }
    .btnReadMore {
        font-size: 15px;
        color: blue
    }
    .btnReadMore:hover {
        font-size: 15px;
         cursor: pointer;;
        color: #4465f7;
    }
    .imgStarSectionContainer{
        display: 'center';
        justify-content: 'flex-end'
    }
    .imgStarSection {
        width: 600px;
        height: 274px;
    }

    .StartSectionIntroduction {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }

    .StartSectionTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .StartSectionSubTitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .StartSectionText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }

    .hide {
        display: block;
        margin-top: -10px;
    }

    .cardSocioSeccion {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        background-color: #D7D4CF;
        width: 100%;
    }

    .cardSocioContainer {
        display: flex;
        flex-direction: row !important;
        justify-content: space-evenly !important;

    }

    .cardSocioTitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .cardSocioText {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: justify;
    }

    .cardSocioContainerIndividual {
        display: flex;
        padding: '5%' !important;
        padding-top: '0%' !important;
    }

    .cardSocio {
        box-shadow: 1px 1px 3px 0px #0000007D;
        height: 460px;
        width: 292px;
        left: 0px;
        top: 0px;
        border-radius: 2px;
        padding: 18px 12px 18px 12px;
        background-color: white;

    }

    .cardSocioImg1 {
        width: 268px;
        height: 219px;

    }

    .cardSocioYear {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #BC6C25;
    }

    .cardSocioText {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: justify;
        color: #000000;
    }
}

@media (max-width: 1280px) {

    .cardSocioImg1 {
        width: 100%;
    }

    .cardSocioImg2 {
        width: 100%;
    }

    .cardSocioImg3 {
        width: 100%;
    }
    .btnReadMore {
        font-size: 13px;
    }

    .cardSocioYear {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #BC6C25;
    }

    .cardSocioText {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: justify;

    }

    .cardSocioTitle {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }
    .btnReadMore {
        font-size: 15px;
        color: blue
    }
    .btnReadMore:hover {
        font-size: 15px;
         cursor: pointer;;
        color: #BC6C25;
    }
    .cardSocio {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        padding: 18px 12px;
        width: 292px;
        height: 480;
        background: #F8F9FA;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.49);
        border-radius: 2px;

    }

    .imgStarSection {
        width: 80%;
    }

    .StartSectionText {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
    }

    .StartSectionSubTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        padding-left: 3%;
    }

    .StartSectionTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .StartSectionIntroduction {
        font-weight: 500;
        font-size: 14px;
        width: 90%;
        line-height: 17px;
        color: #000000;
    }
    .StartSectionContainer{
        display: flex;
        justify-content: center !important;
    }
    .imgStarSectionContainer{
       display: flex !important;
       flex-direction: row !important;
       justify-content: center !important;
       align-items: center !important;

    }
    .cardSocioSeccion{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .StartSectionMobile{
        padding-left: 25px;
    }
}
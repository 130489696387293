@media (min-width:1281px) {
    .globalContainerParticipations{
        display: flex;
        justify-content: end;
    }
    .containerParticipations {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .specialDesktopImg{
        display: flex !important;
        justify-content: center !important;
    }
    .yearsParticipations {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }
    .subtitleParticipations {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

    }

    .textListParticipations {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }

    .imgParticipation1 {
        width: 600px;
        height: 228px;
    }
    .imgParticipation2 {
        width: 600px;
        height: 228px;
    }
    .imgParticipation3 {
        width: 600px;
        height: 472px;
    }
    .imgParticipation5 {
        width: 600px;
        height: 456px;
        padding-top: 3%;
    }
}


@media (max-width:1280px) {
    .globalContainerParticipations{
        display: flex !important;
        flex-direction: column  !important;
    }
    .containerParticipations {
        display: flex  !important;
        flex-direction: column  !important;
        flex-wrap: nowrap  !important;
        align-content: center  !important;
        align-items: center  !important;
    }

    .yearsParticipations {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        padding-left: 20px;
        text-align: left;

    }

    .subtitleParticipations {
        font-size: 15px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        padding-left: 20px;
        text-align: left;

    }
    .textListParticipations {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;

    }

    .divImgParticipations{
        display:'flex' !important;
        justify-content: center !important;
    }
    .imgParticipation1 {
        width: 90%;
    }
    .imgParticipation2 {
        width: 90%;

    }
    .imgParticipation3 {
        width: 90%;

    }
    .imgParticipation5 {
        width: 90%;

    }

}